<template>
<b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
        <b-card class="auth-card" no-body>
            <div class="position-relative image-side">
                <p class="text-white h2">{{ $t('dashboards.magic-is-in-the-details') }}</p>
                <p class="white mb-0">
                    Tu plataforma de confianza para la gestión de tus dispositivos GPS Sole Tracking
                    <br />Si aún no eres miembro, por favor
                    <router-link tag="a" to="/register" class="white">registrate</router-link>.
                </p>
            </div>
            <div class="form-side">
                <router-link tag="a" to="/">
                    <img class="customLogo loginLogo" src="/static/img/logo-sole.svg">
                </router-link>
                <h6 class="mb-4">{{ $t('INICIAR SESIÓN')}}</h6>

                <b-form  class="av-tooltip tooltip-label-bottom" v-on:keyup.enter="formSubmit()"> 

                    <b-form-group :label="$t('Correo electrónico')" class="has-float-label mb-4">
                        <b-form-input type="text" name="usuario" v-model="$v.form.email.$model" :state="!$v.form.email.$error" />
                        <b-form-invalid-feedback v-if="!$v.form.email.required">Favor de ingresar su correo electrónico</b-form-invalid-feedback>
                        <b-form-invalid-feedback v-else-if="!$v.form.email.email">Por favor ingrese un correo electrónico valido</b-form-invalid-feedback>
                        <b-form-invalid-feedback v-else-if="!$v.form.email.minLength">Su correo debe contener al menos 4 caracteres</b-form-invalid-feedback>
                        
                    </b-form-group>

                    <b-form-group :label="$t('Contraseña')" class="has-float-label mb-4">
                        <b-form-input type="password" name="contrasena" v-model="$v.form.password.$model" :state="!$v.form.password.$error" />
                        <b-form-invalid-feedback v-if="!$v.form.password.required">Favor de ingresar su contraseña</b-form-invalid-feedback>
                        <b-form-invalid-feedback v-else-if="!$v.form.password.minLength || !$v.form.password.maxLength">Su contraseña debe contener al menos 6 caracteres</b-form-invalid-feedback>
                    </b-form-group>
                    
                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="$v.form.rememberMe.$model"
                        name="rememberMe"
                        value="true"
                        unchecked-value="false"
                    >
                      Mantener la sesión abierta
                    </b-form-checkbox>
                    

                    <div v-if="errorVerificacion" class="alert alert-warning" role="alert">Tu cuenta requiere verificación, favor de revisar tu bandeja de correo para activar la cuenta, si <a href="/verificacion"> haz click aquí si no recibiste el correo de verificación</a></div>

                    <div    class="d-flex justify-content-between align-items-center mt-4">
                        <router-link tag="a" to="/forgot-password">{{ $t('¿Olvidó su contraseña?')}}</router-link>
                        <b-button type="button" v-on:click="formSubmit()" variant="primary" size="lg" :disabled="processing" :class="{'btn-multiple-state btn-shadow': true,
                    'show-spinner': processing,
                    'show-success': !processing && loginError===false,
                    'show-fail': !processing && loginError }">
                            <span class="spinner d-inline-block">
                                <span class="bounce1"></span>
                                <span class="bounce2"></span>
                                <span class="bounce3"></span>
                            </span>
                            <span class="icon success">
                                <i class="simple-icon-check"></i>
                            </span>
                            <span class="icon fail">
                                <i class="simple-icon-exclamation"></i>
                            </span>
                            <span class="label">{{ $t('Iniciar sesión') }}</span>
                        </b-button>
                    </div>
                </b-form>

            </div>
        </b-card>
    </b-colxx>
</b-row>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    validationMixin
} from "vuelidate";
const {
    required,
    maxLength,
    minLength,
    email
} = require("vuelidate/lib/validators");

export default {
    data() {
        return {
            form: {
                email: "",
                password: "",
                rememberMe: false
            },
            errorVerificacion: false
        };
    },
    mixins: [validationMixin],
    validations: {
        form: {
            password: {
                required,
                minLength: minLength(4)
            },
            email: {
                required,
                email,
                minLength: minLength(4)
            },
            rememberMe: {
                required
            }
        }
    },
    computed: {
        ...mapGetters(["currentUser", "processing", "loginError"])
    },
    methods: {
        ...mapActions(["login"]),
        formSubmit() {
            this.$v.$touch();
            //this.form.email = "piaf-vue@coloredstrategies.com";
            //this.form.password = "piaf123";
            this.$v.form.$touch();
           // if (!this.$v.form.$anyError) {
                this.login({
                    email: this.form.email,
                    password: this.form.password,
                    rememberMe: this.form.rememberMe
                });
            //}
        }
    },
    watch: {
        currentUser(val) {
            
            if (val.token.length > 0) {
                //setTimeout(() => {
                    this.$router.push("/plataforma").catch((error) => {
                        console.log(error);
                    });
                //}, 500);
            }
        },
        loginError(val) {

            if (val != null) {
                this.$notify("error", "Error al iniciar sesión", val.error, {
                    duration: 3000,
                    permanent: false
                });

                
                if(val.status == 409){
                    //significa que no ha verificado su correo
                    this.errorVerificacion = true;
                }
            }
        }
    },
    mounted: function(){
        /*console.log("Cookies");
        console.log(Document.cookie);*/
    }
};
</script>
